import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventFormActions } from "../../../store/redux/event-form-slice";
import { APIProvider as GoogleMapsAPIProvider } from "@vis.gl/react-google-maps";
import useScrollToTop from "../../../hooks/useScrollToTop";

import GooglePlaceAutoComplete from "./GooglePlaceAutoComplete";
import AddressInputForm from "./AddressInputForm";
import FormWrapper from "../../UI/Layout/FormWrapper";
import SectionWrapper from "../../UI/Layout/SectionWrapper";
import AddressConfirmation from "./AddressConfirmation";
import { getAddress } from "../../../utils/get-address";
import { addressValidation } from "../../../utils/validation/address-validation";

import styles from "./index.module.scss";
import { FaChevronRight } from "react-icons/fa6";

export default function LocationStep() {
  useScrollToTop();

  const { location: storedLocation } = useSelector((state) => state.eventForm);
  const {
    venue: storedVenue,
    geometry: storedGeometry,
    addressLine1: storedAddressLine1,
    addressLine2: storedAddressLine2,
    city: storedCity,
    province: storedProvince,
    postalCode: storedPostalCode,
    country: storedCountry,
  } = storedLocation;
  const dispatch = useDispatch();

  const [geometry, setGeometry] = useState(storedGeometry || null);
  const [venue, setVenue] = useState(storedVenue || "");
  const [addressLine1, setAddressLine1] = useState(storedAddressLine1 || "");
  const [addressLine2, setAddressLine2] = useState(storedAddressLine2 || "");
  const [city, setCity] = useState(storedCity || "");
  const [province, setProvince] = useState(storedProvince || "");
  const [postalCode, setPostalCode] = useState(storedPostalCode || "");
  const [country, setCountry] = useState(storedCountry || "");
  const [showAddressInput, setShowAddressInput] = useState(
    storedGeometry ? true : false
  );
  const [addressValidated, setAddressValidated] = useState(false);
  const [addressError, setAddressError] = useState(null);

  const addressObj = {
    venue,
    geometry,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    country,
  };

  const addressSetters = {
    setVenue,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setProvince,
    setPostalCode,
    setCountry,
  };

  const updateAddressHandler = ({
    geometry,
    venue,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    country,
  }) => {
    setGeometry(geometry);
    setVenue(venue);
    setAddressLine1(addressLine1);
    setAddressLine2(addressLine2);
    setCity(city);
    setProvince(province);
    setPostalCode(postalCode);
    setCountry(country);
  };

  /**
   * Updating location states after selecting a place via autocomplete.
   *
   * @param {object} place - The selected place object returned by Google Places.
   * @return {void}
   */
  const selectPlaceHandler = (place) => {
    setAddressError(null);

    const geometry = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    const address = getAddress(place.address_components, geometry, place.name);

    updateAddressHandler(address);
    setShowAddressInput(true);
  };

  /**
   * Updating location states after setting the pin.
   *
   * @param {object} newLocation - The new location object created after setting the pin.
   * @return {void}
   */
  const pinHandler = (newLocation) => {
    setAddressError(null);

    const address = getAddress(
      newLocation.addressComponents,
      newLocation.geometry
    );

    updateAddressHandler(address);
  };

  const resetHandler = () => {
    setGeometry(null);
    setVenue("");
    setAddressLine1("");
    setAddressLine2("");
    setCity("");
    setProvince("");
    setPostalCode("");
    setCountry("");
    setShowAddressInput(false);
    setAddressValidated(false);
    setAddressError(null);
  };

  const submitHandler = () => {
    setShowAddressInput(false);

    // Store location in redux store
    dispatch(eventFormActions.submitLocation(addressObj));
    // Go to next step
    dispatch(eventFormActions.nextStep());
  };

  const discardHandler = () => {
    // Reset location in redux store
    dispatch(eventFormActions.discardLocation());
    // Reset form
    resetHandler();
  };

  const validateAddress = async () => {
    const validatedResult = await addressValidation({
      address: {
        addressLines: [addressLine1, addressLine2],
        administrativeArea: province,
        locality: city,
        postalCode: postalCode,
      },
    });

    console.log(validatedResult);

    if (validatedResult.hasOwnProperty("error")) {
      setAddressError(validatedResult.error);
      return;
    }

    const geometry = {
      lat: validatedResult.geocode.location.latitude,
      lng: validatedResult.geocode.location.longitude,
    };
    console.log(validatedResult);

    const address = getAddress(
      validatedResult.address.addressComponents,
      geometry,
      venue
    );

    updateAddressHandler(address);
    setAddressValidated(true);
  };

  return (
    <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_APIKEY}>
      <FormWrapper
        formTitle="Location"
        description="Help people to discover your event and let patrons know where to show up"
      >
        {!addressValidated && (
          <>
            <SectionWrapper className={styles["address-form"]}>
              <GooglePlaceAutoComplete onSelectPlace={selectPlaceHandler} />
              <button
                type="button"
                className={styles["add-address-btn"]}
                onClick={() => setShowAddressInput(true)}
              >
                Add an address manually <FaChevronRight />
              </button>
              {showAddressInput && (
                <AddressInputForm
                  address={addressObj}
                  addressSetters={addressSetters}
                  error={addressError}
                  onValidateAddress={validateAddress}
                  onReset={resetHandler}
                  onPinLocation={pinHandler}
                />
              )}
            </SectionWrapper>
          </>
        )}
        {addressValidated && (
          <AddressConfirmation
            address={addressObj}
            onReset={resetHandler}
            onDiscard={discardHandler}
            onSave={submitHandler}
          />
        )}
      </FormWrapper>
    </GoogleMapsAPIProvider>
  );
}
