import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import RootLayout from "./components/UI/Layout/RootLayout";
import ErrorPage from "./pages/Error";
import AuthContextProvider from "./store/context/auth-context";

import { queryClient } from "./utils/https";

import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/redux";
import HomePage from "./pages/Home/Home";
import EventsLayout from "./components/UI/Layout/EventsLayout";
import AddEventPage from "./pages/Events/AddEvent";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <HomePage /> },
      {
        path: "events",
        element: <EventsLayout />,
        children: [{ path: "add-event", element: <AddEventPage /> }],
      },
    ],
  },
]);

function App() {
  return (
    <ReduxProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <RouterProvider router={router} />
        </AuthContextProvider>
      </QueryClientProvider>
    </ReduxProvider>
  );
}

export default App;
