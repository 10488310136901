import styles from "./SelectGroup.module.scss";

export default function SelectGroup({
  id,
  label,
  className,
  onChange,
  required,
  options,
}) {
  return (
    <div className={styles["select-group"]}>
      <label htmlFor={id}>
        {label} {required && <span className={styles.required}>*</span>}
      </label>
      <select
        id={id}
        className={className}
        required={required}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
}
