import {
  FaFacebookF,
  FaInstagram,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import styles from "./SocialIcon.module.scss";

export default function SocialIcon({ platform, className }) {
  let icon;
  switch (platform) {
    case "instagram":
      icon = <FaInstagram />;
      break;
    case "facebook":
      icon = <FaFacebookF />;
      break;
    case "youtube":
      icon = <FaYoutube />;
      break;
    case "x":
      icon = <FaXTwitter />;
      break;
    case "tiktok":
      icon = <FaTiktok />;
      break;
    default:
      break;
  }

  return <div className={`${styles["social-icon"]} ${className}`}>{icon}</div>;
}
