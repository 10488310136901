export const extractYoutubeVideoId = (url) => {
  const videoIdMatch = url.match(
    /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
  );
  return videoIdMatch ? videoIdMatch[1] : null;
};

export const fetchYouTubeDetails = async (videoId) => {
  const apiUrl = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return {
      title: data.title,
      thumbnail: data.thumbnail_url,
    };
  } catch (error) {
    console.error("Error fetching video details:", error);
    return null;
  }
};
