import { useState } from "react";

import styles from "./FeatureImage.module.scss";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import useImageCropper from "../../../hooks/useImageCropper";
import { Modal } from "react-bootstrap";
import ImageUploader from "../../UI/Image/ImageUploader";
import ImageCropper from "../../UI/Image/ImageCropper";
import ModalHeader from "../../UI/Layout/ModalHeader";

export default function FeatureImage({ featureImage, onUpdate }) {
  const [showCropModal, setShowCropModal] = useState(false);

  const { originalImg, setOriginalImg, onCropDone, onCropCancel } =
    useImageCropper();
  const addImagesHandler = (dataUrl) => {
    setShowCropModal(true);
    setOriginalImg(dataUrl);
  };

  const removeImageHandler = (link) => {
    onUpdate((prev) => ({
      ...prev,
      featureImage: [],
    }));
  };

  return (
    <SectionWrapper
      title="Feature Image"
      description="This image will be shown in the event listings. Tips to select a great picture."
    >
      <ImageUploader
        id="feature-image"
        images={featureImage}
        onAddImages={addImagesHandler}
        onRemoveImage={removeImageHandler}
        className={styles.container}
      />
      <p className={styles["image-requirements"]}>
        Recommended image size: 1080 x 1080px - Maximum file size: 10MB -
        Supported image files: JPEG or PNG
      </p>
      <Modal
        show={showCropModal}
        onHide={() => setShowCropModal(false)}
        size="lg"
        centered
      >
        <ModalHeader
          title="Thumbnail Image"
          description="Zoom to select the desired area for the event banner"
        />
        <ImageCropper
          image={originalImg}
          aspectRatio={1 / 1}
          onCropDone={(imgCroppedArea) =>
            onCropDone(
              imgCroppedArea,
              (link) =>
                onUpdate((prev) => ({
                  ...prev,
                  featureImage: [link],
                })),
              () => setShowCropModal(false)
            )
          }
          onCropCancel={() => onCropCancel(() => setShowCropModal(false))}
        />
      </Modal>
    </SectionWrapper>
  );
}
