import PageWrapper from "../../components/UI/Layout/PageWrapper";
import ProfileStats from "../../components/Home/ProfileStats";
import MarketingTips from "../../components/Home/MarketingTips";
import Button from "../../components/UI/Buttons/Button";
import SlideBanner from "../../components/UI/SlideBanner";

import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";

const banners = [
  {
    title: "Streaming",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/3f2cb6734bb2e66186dc2f4120c2f7fb37006eb9daeaf08b4eab2d91914d56ad?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
  },
  {
    title: "Live Events",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/3f2cb6734bb2e66186dc2f4120c2f7fb37006eb9daeaf08b4eab2d91914d56ad?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
  },
  {
    title: "Virtual Concerts",
    image:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/3f2cb6734bb2e66186dc2f4120c2f7fb37006eb9daeaf08b4eab2d91914d56ad?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408",
  },
];

export default function HomePage() {
  const navigate = useNavigate();
  return (
    <PageWrapper>
      <h1 className={styles.greeting}>Hi Alex!</h1>
      <div className={styles.content}>
        <section className={styles["call-to-action"]}>
          <p>Are you ready for your next event !?</p>
          <Button
            variant="primary"
            onClick={() => navigate("/events/add-event")}
          >
            Create Event
          </Button>
        </section>
        <ProfileStats />
        <div className={styles["right-panel"]}>
          <SlideBanner slides={banners} />
          <MarketingTips />
        </div>
      </div>
    </PageWrapper>
  );
}
