import { useState, useEffect } from "react";

import Button from "./Buttons/Button";

import styles from "./SlideBanner.module.scss";

export default function SlideBanner({ slides }) {
  const [currSlide, setCurrSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrSlide((prevBanner) => (prevBanner + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currSlide, slides.length]);

  return (
    <section className={styles.banner}>
      <div
        className={styles["banner-slider"]}
        style={{
          transform: `translateX(-${100 * currSlide}%)`,
        }}
      >
        {slides.map((banner, index) => (
          <div key={index} className={styles["banner-slide"]}>
            <img
              src={banner.image}
              alt={banner.title}
              className={styles["banner-background"]}
            />
            <h2 className={styles["banner-title"]}>{banner.title}</h2>
            <Button variant="secondary" className={styles["learn-more-btn"]}>
              Learn More
            </Button>
          </div>
        ))}
      </div>
      <div className={styles["dot-indicators"]}>
        {slides.map((_, index) => (
          <span
            key={index}
            className={`${styles.dot} ${
              index === currSlide ? styles["active-dot"] : ""
            }`}
            onClick={() => setCurrSlide(index)}
          />
        ))}
      </div>
    </section>
  );
}
