export const extractMapComponents = (components, type) => {
  const component = components.find((component) =>
    component.types
      ? component.types.includes(type)
      : component.componentType.includes(type)
  );

  if (!component) {
    return "";
  }

  if (component.types) {
    return component.long_name;
  }

  return component.componentName.text;
};

export const getAddress = (addressComponents, geometry, venue = "") => {
  return {
    venue,
    geometry,
    addressLine1: `${extractMapComponents(
      addressComponents,
      "street_number"
    )} ${extractMapComponents(addressComponents, "route")}`,
    addressLine2: extractMapComponents(addressComponents, "subpremise"),
    city: extractMapComponents(addressComponents, "locality"),
    province: extractMapComponents(
      addressComponents,
      "administrative_area_level_1"
    ),
    postalCode: extractMapComponents(addressComponents, "postal_code"),
    country: extractMapComponents(addressComponents, "country"),
  };
};
