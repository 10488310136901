import { createSlice } from "@reduxjs/toolkit";

const eventFormSlice = createSlice({
  name: "event-form",
  initialState: {
    currStep: 4,
    title: "",
    performance: "",
    category: "",
    type: "",
    format: "in-person",
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    timeZone: "",
    language: [],
    mainImages: [],
    featureImage: [],
    description: {
      title: "",
      content: "",
      videos: [],
      images: [],
    },
    socialMediaAccounts: [],
    location: {
      venue: "",
      geometry: null,
      addressLine1: "",
      addressLine2: "",
      city: "",
      province: "",
      postalCode: "",
      country: "",
    },
  },
  reducers: {
    nextStep(state) {
      state.currStep++;
    },
    prevStep(state) {
      state.currStep--;
    },
    step(state, action) {
      state.currStep = action.payload;
    },
    submitBasicInfo(state, action) {
      state.title = action.payload.title;
      state.performance = action.payload.performance;
      state.category = action.payload.category;
      state.type = action.payload.type;
      state.format = action.payload.format;
      state.startTime = action.payload.startTime;
      state.endTime = action.payload.endTime;
      state.timeZone = action.payload.timeZone;
      state.language = action.payload.language;
    },
    discardBasicInfo(state) {
      state.title = "";
      state.performance = "";
      state.category = "";
      state.type = "";
      state.format = "in-person";
      state.startTime = new Date().toISOString();
      state.endTime = new Date().toISOString();
      state.timeZone = "";
      state.language = [];
    },
    submitMainPage(state, action) {
      state.mainImages = action.payload.mainImages;
      state.featureImage = action.payload.featureImage;
      state.description = action.payload.description;
      state.socialMediaAccounts = action.payload.socialMediaAccounts;
    },
    discardMainPage(state) {
      state.mainImages = [];
      state.featureImage = [];
      state.description = {
        title: "",
        content: "",
        videos: [],
        images: [],
      };
      state.socialMediaAccounts = [];
    },
    submitLocation(state, action) {
      state.location = action.payload;
    },
    discardLocation(state) {
      state.location = {
        venue: "",
        geometry: null,
        addressLine1: "",
        addressLine2: "",
        city: "",
        province: "",
        postalCode: "",
        country: "",
      };
    },
  },
});

export const eventFormActions = eventFormSlice.actions;
export default eventFormSlice;
