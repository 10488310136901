import { FaCheck, FaChevronLeft } from "react-icons/fa6";
import styles from "./EventNav.module.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Buttons/Button";

import { timeFormatter } from "../../../../utils/time-formatter";
import { eventFormActions } from "../../../../store/redux/event-form-slice";

export default function EventNav() {
  const steps = [
    { step: 1, text: "Basic Event Information" },
    { step: 2, text: "Main Event Page Set Up" },
    { step: 3, text: "Location Setting" },
    { step: 4, text: "Ticketing" },
    { step: 5, text: "Publish" },
  ];

  const { currStep, title, startTime } = useSelector(
    (state) => state.eventForm
  );

  const dispatch = useDispatch();

  return (
    <>
      <nav className={styles["event-nav"]}>
        <div className={styles.controls}>
          <FaChevronLeft />
          <span>Go back to Events</span>
        </div>
        {title && startTime && (
          <div className={styles["event-info"]}>
            <h2>{title}</h2>
            <p>{timeFormatter(startTime)}</p>
            <Button variant="secondary">Preview</Button>
          </div>
        )}
        <ol className={styles["form-steps"]}>
          {steps.map((step) => (
            <li
              key={step.step}
              className={`${styles.step} ${
                step.step < currStep
                  ? styles["completed-step"]
                  : step.step === currStep
                  ? styles["active-step"]
                  : styles["inactive-step"]
              }`}
              style={{ cursor: step.step < currStep ? "pointer" : "default" }}
              onClick={() =>
                step.step < currStep &&
                dispatch(eventFormActions.step(step.step))
              }
            >
              <span className={styles["step-number"]}>
                {step.step < currStep ? <FaCheck /> : step.step}
              </span>
              <span className={styles["step-title"]}>{step.text}</span>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
}
