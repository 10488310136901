import { useEffect } from "react";

/**
 * Scrolls the window to the top when the component mounts.
 *
 * @return {void}
 */
export default function useScrollToTop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
}
