import React, { useState } from "react";
import styles from "./SocialMedia.module.scss";

import SocialInputGroup from "./SocialInputGroup";

import { FaAngleDown } from "react-icons/fa6";
import SectionWrapper from "../../../UI/Layout/SectionWrapper";
import SocialIcon from "./SocialIcon";

const socialPlatforms = ["instagram", "facebook", "youtube", "x", "tiktok"];

function SocialMedia({ socialMediaAccounts, onUpdate }) {
  const [showAccordion, setShowAccordion] = useState(false);

  const addAccountHandler = (platform, accountId) => {
    if (socialMediaAccounts.find((account) => account.platform === platform)) {
      return;
    }

    if (accountId.trim()) {
      onUpdate((prev) => ({
        ...prev,
        socialMediaAccounts: [
          ...prev.socialMediaAccounts,
          { platform, accountId },
        ],
      }));
    }
  };

  return (
    <SectionWrapper
      title="Social Media"
      description="Add your social media accounts"
    >
      <div className={styles["social-media-container"]}>
        {socialMediaAccounts.length !== 0 && (
          <ul className={styles["selected-social-list"]}>
            {socialMediaAccounts.map((account) => (
              <li key={account.platform} className={styles["selected-social"]}>
                <SocialIcon
                  platform={account.platform}
                  className={styles["selected-social-icons"]}
                />
                <p>{account.accountId}</p>
              </li>
            ))}
          </ul>
        )}
        {!socialMediaAccounts.some(
          (account) => account.platform === "instagram"
        ) && (
          <SocialInputGroup
            platform="instagram"
            onAddAccount={addAccountHandler.bind(null, "instagram")}
          />
        )}
        {socialMediaAccounts.length !== socialPlatforms.length && (
          <div className={styles.accordion}>
            <div className={styles["accordion-container"]}>
              <button
                onClick={() => setShowAccordion((prev) => !prev)}
                className={styles["add-social-btn"]}
              >
                <span>Add More Social Media</span>
                <FaAngleDown
                  className={`${styles["accordion-arrow"]} ${
                    showAccordion ? styles["rotate-up"] : ""
                  }`}
                />
              </button>
              {showAccordion && (
                <div className={styles["social-input-list"]}>
                  {socialPlatforms
                    .filter(
                      (platform) =>
                        platform !== "instagram" &&
                        !socialMediaAccounts.some(
                          (account) => account.platform === platform
                        )
                    )
                    .map((platform) => (
                      <SocialInputGroup
                        key={platform}
                        platform={platform}
                        onAddAccount={addAccountHandler.bind(null, platform)}
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </SectionWrapper>
  );
}

export default SocialMedia;
