import { NavLink } from "react-router-dom";
import styles from "./NavItem.module.scss";

export default function NavItem({ title, icon, path, onClose }) {
  return (
    <NavLink
      to={`/${path}`}
      className={({ isActive }) =>
        `${styles["nav-item"]} ${isActive ? styles.active : ""}`
      }
      onClick={onClose}
    >
      {icon}
      <span>{title}</span>
    </NavLink>
  );
}
