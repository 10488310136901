import SectionWrapper from "../../UI/Layout/SectionWrapper";
import Checkbox from "../../UI/Input/Checkbox";

import styles from "./EventLanguage.module.scss";

const languageOptions = [
  { text: "English", value: "en" },
  { text: "French", value: "fr" },
  { text: "Spanish", value: "es" },
  { text: "Dutch", value: "nl" },
  { text: "Arabic", value: "ar" },
];

export default function EventLanguage({ language, onUpdate }) {
  const checkHandler = (event) => {
    if (language.includes(event.target.value)) {
      onUpdate((prev) => ({
        ...prev,
        language: language.filter((lang) => lang !== event.target.value),
      }));
    } else {
      onUpdate((prev) => ({
        ...prev,
        language: [...language, event.target.value],
      }));
    }
  };

  return (
    <SectionWrapper title="Language" className={styles["section-wrapper"]}>
      {languageOptions.map((lang) => (
        <Checkbox
          key={lang.value}
          id={`lang-${lang.value}`}
          text={lang.text}
          value={lang.value}
          checked={language.includes(lang.value)}
          onCheck={checkHandler}
        />
      ))}
    </SectionWrapper>
  );
}
