export function NavReportsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M11.957 13.5782H1.026C0.753959 13.5774 0.4933 13.469 0.301031 13.2767C0.108761 13.0845 0.000527597 12.8239 0 12.5521L0 10.1603C0.000527597 9.88853 0.108761 9.62801 0.301031 9.43572C0.4933 9.24344 0.753959 9.13506 1.026 9.13426H4.106V5.71739C4.10679 5.44577 4.21514 5.1855 4.40738 4.99344C4.59962 4.80137 4.86013 4.69312 5.132 4.69233H8.561V1.63213C8.5623 1.35686 8.67206 1.09317 8.86652 0.898144C9.06097 0.703119 9.32448 0.592449 9.6 0.590088L11.954 0.590088C12.2307 0.590874 12.4958 0.700818 12.6917 0.895984C12.8876 1.09115 12.9984 1.35573 13 1.63213V12.5361C12.9984 12.812 12.888 13.0762 12.6928 13.2712C12.4975 13.4663 12.2331 13.5766 11.957 13.5782Z"
        fill="#2A2C3A"
      />
    </svg>
  );
}

export function NavDisputeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
    >
      <path
        d="M10.074 8.84307C10.2591 8.84307 10.4097 8.69176 10.4097 8.50574C10.4097 8.31976 10.2591 8.16846 10.074 8.16846C9.88888 8.16846 9.73828 8.31976 9.73828 8.50574C9.73824 8.69176 9.88888 8.84307 10.074 8.84307Z"
        fill="white"
      />
      <path
        d="M4.58747 9.81959C4.72871 10.06 4.97902 10.2035 5.25712 10.2035H14.8916C15.1696 10.2035 15.42 10.06 15.5613 9.81959C15.7033 9.57773 15.7066 9.28708 15.5701 9.04211L10.7528 0.399343C10.6134 0.149301 10.3598 0 10.0743 0C9.78888 0 9.53526 0.149301 9.39588 0.399343L4.57862 9.04207C4.44211 9.28708 4.44537 9.57773 4.58747 9.81959ZM9.46844 2.58302C9.49807 2.5526 9.53872 2.53544 9.58118 2.53544H10.5675C10.6099 2.53544 10.6506 2.5526 10.6802 2.58302C10.7098 2.61344 10.7259 2.65452 10.7248 2.69698L10.6015 7.39681C10.5993 7.48209 10.5295 7.55009 10.4442 7.55009H9.70443C9.61912 7.55009 9.54935 7.48209 9.54706 7.39681L9.42378 2.69698C9.42271 2.65452 9.43881 2.61344 9.46844 2.58302ZM10.0744 7.85365C10.4331 7.85365 10.7249 8.14619 10.7249 8.50575C10.7249 8.86535 10.4331 9.15789 10.0744 9.15789C9.71565 9.15789 9.42381 8.86535 9.42381 8.50575C9.42378 8.14619 9.71561 7.85365 10.0744 7.85365Z"
        fill="black"
      />
      <path
        d="M10.2903 7.23532L10.4053 2.85034H9.74219L9.85721 7.23532H10.2903Z"
        fill="white"
      />
      <path
        d="M17.8628 14.6568C18.7405 13.5416 19.2045 12.196 19.2045 10.7655C19.2045 7.4635 16.6354 4.72846 13.423 4.5415L15.6769 8.5853H16.7809C16.8678 8.5853 16.9383 8.65578 16.9383 8.74271C16.9383 8.82964 16.8678 8.90012 16.7809 8.90012H15.8519C16.0371 9.24126 16.0307 9.64355 15.8337 9.97899C15.6353 10.3167 15.2835 10.5183 14.8926 10.5183H11.3874C11.2667 10.6902 11.1387 10.855 11.0038 11.0126H16.7809C16.8678 11.0126 16.9383 11.0831 16.9383 11.1701C16.9383 11.257 16.8678 11.3275 16.7809 11.3275H10.7163C9.81177 12.2544 8.65526 12.8858 7.39844 13.1413C8.33883 15.4569 10.5951 17 13.0708 17C14.2269 17 15.3533 16.671 16.3283 16.0486C16.3685 16.023 16.4178 16.0169 16.4629 16.032L18.468 16.7025L17.8371 14.8038C17.8204 14.7536 17.8301 14.6984 17.8628 14.6568ZM16.7809 13.7548H9.68329C9.59636 13.7548 9.52588 13.6843 9.52588 13.5974C9.52588 13.5104 9.59636 13.4399 9.68329 13.4399H16.7809C16.8678 13.4399 16.9383 13.5104 16.9383 13.5974C16.9383 13.6843 16.8678 13.7548 16.7809 13.7548Z"
        fill="black"
      />
      <path
        d="M10.9971 10.5183H5.25737C4.86645 10.5183 4.51468 10.3167 4.31627 9.97895C4.11703 9.63982 4.11243 9.23225 4.30391 8.88871L4.83878 7.92911H2.74617C2.65924 7.92911 2.58876 7.85863 2.58876 7.7717C2.58876 7.68477 2.65924 7.6143 2.74617 7.6143H5.01421L5.92108 5.98725H2.74617C2.65924 5.98725 2.58876 5.91677 2.58876 5.82985C2.58876 5.74292 2.65924 5.67244 2.74617 5.67244H6.09655L7.00341 4.0454H3.55273C3.4658 4.0454 3.39532 3.97492 3.39532 3.88799C3.39532 3.80106 3.4658 3.73058 3.55273 3.73058H7.17888L8.67602 1.0445C7.87679 0.673453 7.02238 0.485352 6.1337 0.485352C2.75156 0.485352 0 3.28217 0 6.71987C0 8.15035 0.463959 9.49594 1.3417 10.6112C1.3744 10.6528 1.38404 10.708 1.36736 10.7582L0.73647 12.6568L2.74153 11.9864C2.78658 11.9713 2.83605 11.9774 2.87615 12.003C3.85113 12.6254 4.97758 12.9544 6.1337 12.9544C8.04238 12.9544 9.83824 12.0489 10.9971 10.5183Z"
        fill="black"
      />
    </svg>
  );
}

export function NavInfluencersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M5.3803 11.5076C5.53654 11.2696 5.63856 11.0095 5.69093 10.743C6.01535 9.10894 4.24714 7.93524 3.04695 8.96649C2.97711 9.02622 2.91553 9.09331 2.86498 9.16592C2.81444 9.09423 2.75378 9.02622 2.68301 8.96649C1.48459 7.93534 -0.285406 9.10986 0.0390336 10.743C0.0914191 11.0095 0.19435 11.2696 0.35058 11.5076C0.5013 11.7374 0.992071 12.661 2.86516 14.1434C4.73722 12.66 5.22799 11.7372 5.3788 11.5076H5.3803Z"
        fill="#2A2C3A"
      />
      <path
        d="M14.386 11.0449C14.8409 12.9757 13.6461 14.9094 11.7151 15.3652C9.78519 15.821 7.85058 14.6253 7.3958 12.6953C6.93996 10.7653 8.1347 8.83072 10.0657 8.375C11.9956 7.91916 13.9302 9.11481 14.386 11.0449Z"
        fill="#2A2C3A"
      />
      <path
        d="M19.6782 8.38363C18.2014 8.38363 17.0039 9.58111 17.0039 11.058C17.0039 12.1139 17.616 13.0265 18.5047 13.4613V14.3371L19.3621 13.7122C19.466 13.7241 19.5707 13.7315 19.6783 13.7315C21.1552 13.7315 22.3526 12.534 22.3526 11.0571C22.3526 9.58027 21.1551 8.38281 19.6783 8.38281L19.6782 8.38363ZM18.4385 11.3603C18.2712 11.3603 18.1361 11.2252 18.1361 11.0579C18.1361 10.8906 18.2712 10.7555 18.4385 10.7555C18.6057 10.7555 18.7408 10.8906 18.7408 11.0579C18.7408 11.2252 18.6057 11.3603 18.4385 11.3603ZM19.6782 11.3603C19.511 11.3603 19.3759 11.2252 19.3759 11.0579C19.3759 10.8906 19.5119 10.7555 19.6782 10.7555C19.8455 10.7555 19.9806 10.8906 19.9806 11.0579C19.9806 11.2252 19.8455 11.3603 19.6782 11.3603ZM20.918 11.3603C20.7507 11.3603 20.6156 11.2252 20.6156 11.0579C20.6156 10.8906 20.7516 10.7555 20.918 10.7555C21.0853 10.7555 21.2204 10.8906 21.2204 11.0579C21.2204 11.2252 21.0853 11.3603 20.918 11.3603Z"
        fill="#2A2C3A"
      />
      <path
        d="M8.4824 6.53396H9.37662C9.51447 6.53396 9.62568 6.42368 9.62568 6.28766V3.44699C9.62568 3.31097 9.51355 3.20068 9.37662 3.20068H8.4824C8.34454 3.20068 8.23242 3.31097 8.23242 3.44699V6.28766C8.23242 6.42367 8.34454 6.53396 8.4824 6.53396Z"
        fill="#2A2C3A"
      />
      <path
        d="M11.837 6.5837H13.2082C13.3056 6.5837 13.404 6.5644 13.4904 6.51937C13.5005 6.51386 13.5106 6.50834 13.5198 6.50283C13.8469 6.3034 13.7174 5.78873 13.3341 5.78231H13.3259H13.5087C13.6466 5.78231 13.7707 5.72625 13.8607 5.63618C13.9508 5.54612 14.0069 5.42113 14.0069 5.28328C14.0069 5.00848 13.7835 4.78516 13.5087 4.78516H13.6916C13.8286 4.78516 13.9536 4.7291 14.0436 4.63904C14.1337 4.54897 14.1897 4.42398 14.1897 4.28613C14.1897 4.01133 13.9673 3.78801 13.6916 3.78801H13.8056C13.9434 3.78801 14.0684 3.73195 14.1585 3.64189C14.2559 3.54355 14.3138 3.4057 14.3037 3.25407C14.2853 2.98847 14.1162 2.79086 13.8506 2.79086H12.4592C12.2138 2.79086 12.0153 2.59235 12.0153 2.34698V1.62646C12.0153 1.41784 11.9574 1.21749 11.8517 1.04656C11.7469 0.873784 11.5944 0.728568 11.4069 0.633918C11.2295 0.543854 10.8665 0.523635 10.8665 0.723065V1.32228C10.8665 1.85072 10.7277 2.37458 10.4383 2.81755C10.3087 3.01606 10.1469 3.2017 9.95117 3.32392V6.20882C10.1092 6.23455 10.2646 6.27683 10.4134 6.33748C10.8684 6.52221 11.3499 6.58104 11.8361 6.58104L11.837 6.5837Z"
        fill="#2A2C3A"
      />
      <path
        d="M14.6992 14.002C13.952 15.3336 12.5275 16.2352 10.8906 16.2352C9.25476 16.2352 7.82927 15.3346 7.08299 14.002C5.56568 15.0451 4.49689 16.6937 4.22656 18.5989C5.93779 20.2945 8.29229 21.3412 10.8913 21.3412C13.4904 21.3412 15.845 20.2936 17.5561 18.5989C17.285 16.6928 16.2171 15.0442 14.6997 14.002H14.6992Z"
        fill="#2A2C3A"
      />
    </svg>
  );
}
