import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useRef } from "react";
import InputGroup from "../../UI/Input/InputGroup";

import styles from "./GooglePlaceAutoComplete.module.scss";

export default function GooglePlaceAutoComplete({ onSelectPlace }) {
  const addressRef = useRef(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (!places || !addressRef.current) {
      return;
    }

    // Initialize  Google Places Autocomplete
    const options = {
      fields: ["geometry", "name", "formatted_address", "address_components"],
    };
    const autocomplete = new places.Autocomplete(addressRef.current, options);

    // Event listener for when the place is selected
    const selectPlaceHandler = () => {
      const place = autocomplete.getPlace();
      if (place) {
        console.table(place);
        onSelectPlace(place);
        addressRef.current.value = "";
      }
    };

    // Attach place_changed listener
    const placeChangedListener = autocomplete.addListener(
      "place_changed",
      selectPlaceHandler
    );

    // Clean up the listener when component unmounts or dependencies change
    return () => {
      placeChangedListener.remove();
    };
  }, [places, onSelectPlace]);

  return (
    <InputGroup
      id="add-event-google-autocomplete"
      ref={addressRef}
      type="text"
      placeholder="Enter a location"
      label="Find Location"
      className={styles["text-input"]}
    />
  );
}
