import { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";

import styles from "./DatePicker.module.scss";
import { FaAngleRight, FaCalendarDays } from "react-icons/fa6";
import { FaClock } from "react-icons/fa";

export default function DatePicker({
  onChangeDate,
  date,
  isSelected,
  isDisabled,
  isTimeOnly,
  options,
}) {
  const CustomDatePicker = forwardRef(({ value, onClick, className }, ref) => (
    <button
      type="button"
      className={className}
      onClick={onClick}
      ref={ref}
      disabled={isDisabled}
    >
      {isTimeOnly ? (
        <FaClock
          style={{
            color: !isSelected && !isDisabled ? "#27aae1" : "inherit",
          }}
        />
      ) : (
        <FaCalendarDays
          style={{
            color: !isSelected && !isDisabled ? "#27aae1" : "inherit",
          }}
        />
      )}

      {value}
      <FaAngleRight />
    </button>
  ));

  return (
    <ReactDatePicker
      placeholderText="Choose From Calendar"
      selected={date}
      onChange={(date) => onChangeDate(date)}
      {...options}
      customInput={
        <CustomDatePicker
          className={`${styles.button} ${
            isSelected ? styles.active : styles.inactive
          }`}
        />
      }
    />
  );
}
