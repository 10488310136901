import styles from "./ImageThumbnail.module.scss";
import { FaTrash } from "react-icons/fa6";

export default function ImageThumbnail({ image, onRemove }) {
  const removeImageHandler = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div className={styles["preview-card"]}>
      <img src={image} alt="preview" className={styles["preview-image"]} />
      <button className={styles["remove-button"]} onClick={removeImageHandler}>
        <FaTrash />
      </button>
    </div>
  );
}
