import { Link } from "react-router-dom";

import styles from "./MarketingTips.module.scss";

const tips = [
  { title: "How to market your event?", link: "#" },
  { title: "More ways to host streaming events", link: "#" },
];

export default function MarketingTips() {
  return (
    <section className={styles["marketing-tips"]}>
      <div className={styles["tip-header"]}>
        <div>
          <h2>Marketing Tips</h2>
          <p>Do you want to know more?</p>
        </div>
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ae2915ee392cec9e76fc6df59d4643ef50c6c1b1c4350565bd2374ecc97a53ef?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408"
          alt="Marketing icon"
          className={styles["tip-icon"]}
        />
      </div>
      <div className={styles["tip-heading"]}>
        {tips.map((tip) => (
          <Link key={tip.title} to={tip.link}>
            <p>{tip.title}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}
