import { useState } from "react";
import RadioButtons from "../../UI/Buttons/RadioButtons";
import FormWrapper from "../../UI/Layout/FormWrapper";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./index.module.scss";
import { HiQuestionMarkCircle } from "react-icons/hi";
import Button from "../../UI/Buttons/Button";
import HorizontalRule from "../../UI/HorizontalRule";

const admissionTypeOptions = [
  { text: "Paid", value: "paid" },
  { text: "Free", value: "free" },
];

export default function TicketingStep() {
  const [ticketingInfo, setTicketingInfo] = useState({
    admissionType: "paid",
    taxMode: "",
  });

  return (
    <FormWrapper formTitle="Add Tickets">
      <SectionWrapper
        className={styles["section-sm"]}
        title="Select the type of admission"
        titleOptions={{
          className: styles["title-sm"],
          tooltipIcon: (
            <HiQuestionMarkCircle
              size={24}
              onClick={() => console.log("Hi")}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
      >
        <RadioButtons
          buttons={admissionTypeOptions}
          currentState={ticketingInfo.admissionType}
          onChangeValue={(value) =>
            setTicketingInfo({ ...ticketingInfo, admissionType: value })
          }
          className={styles["radio-buttons"]}
        />
      </SectionWrapper>
      <HorizontalRule />
      <SectionWrapper
        className={styles["section-sm"]}
        title="Tax Mode"
        titleOptions={{
          className: styles["title-sm"],
          tooltipIcon: (
            <HiQuestionMarkCircle
              size={24}
              onClick={() => console.log("Hi")}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
      ></SectionWrapper>
      <HorizontalRule />
      <SectionWrapper
        className={styles["section-sm"]}
        title="Add Coupon Code"
        titleOptions={{
          className: styles["title-sm"],
          tooltipIcon: (
            <HiQuestionMarkCircle
              size={24}
              onClick={() => console.log("Coupon code")}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
        description="After applying the coupon code, the discount will be reflected in the shopping cart total."
      >
        <Button variant="primary">Add Coupon</Button>
      </SectionWrapper>
    </FormWrapper>
  );
}
