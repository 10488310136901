import InputGroup from "../../UI/Input/InputGroup";
import SelectGroup from "../../UI/Input/SelectGroup";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./EventInfo.module.scss";

const performanceOptions = [{ text: "Select or add a category", value: "" }];
const typeOptions = [{ text: "Select type", value: "" }];

export default function EventInfo({
  title,
  performance,
  category,
  type,
  onUpdate,
}) {
  return (
    <SectionWrapper title="Event Details" className={styles["section-wrapper"]}>
      <InputGroup
        id="event-title"
        value={title}
        onChange={(e) =>
          onUpdate((prev) => ({ ...prev, title: e.target.value }))
        }
        type="text"
        label="Event Title"
        placeholder="Title"
        className={styles["text-input"]}
        required={true}
      />
      <InputGroup
        id="event-performance"
        value={performance}
        onChange={(e) =>
          onUpdate((prev) => ({ ...prev, performance: e.target.value }))
        }
        type="text"
        label="Performance"
        placeholder="Ex.Presented by Juice Smith..."
        className={styles["text-input"]}
      />

      <div className={styles["input-row"]}>
        <SelectGroup
          id="event-category"
          value={category}
          onChange={(e) =>
            onUpdate((prev) => ({ ...prev, category: e.target.value }))
          }
          label="Category"
          className={styles["select-input"]}
          options={performanceOptions}
        />
        <SelectGroup
          id="event-type"
          value={type}
          onChange={(e) =>
            onUpdate((prev) => ({ ...prev, type: e.target.value }))
          }
          label="Type"
          className={styles["select-input"]}
          options={typeOptions}
        />
      </div>
    </SectionWrapper>
  );
}
