import { useState } from "react";
import useImageCropper from "../../../hooks/useImageCropper";

import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./MainImages.module.scss";
import { Modal } from "react-bootstrap";
import ImageUploader from "../../UI/Image/ImageUploader";
import ImageCropper from "../../UI/Image/ImageCropper";
import ModalHeader from "../../UI/Layout/ModalHeader";

export default function MainImages({ mainImages, onUpdate }) {
  const [showCropModal, setShowCropModal] = useState(false);

  const { originalImg, setOriginalImg, onCropDone, onCropCancel } =
    useImageCropper();

  const addImagesHandler = (dataUrl) => {
    setShowCropModal(true);
    setOriginalImg(dataUrl);
  };

  const removeImageHandler = (link) => {
    onUpdate((prev) => ({
      ...prev,
      mainImages: prev.mainImages.filter((image) => image !== link),
    }));
  };

  return (
    <SectionWrapper
      title="Main Event Image"
      description="This image will be the header of your event page. Tips to select a great picture."
    >
      <ImageUploader
        id="main-event-image"
        images={mainImages}
        onAddImages={addImagesHandler}
        onRemoveImage={removeImageHandler}
        className={styles.container}
      />
      <p className={styles["image-requirements"]}>
        Recommended image size: 2200 x 1080px - Maximum file size: 10MB -
        Supported image files: JPEG or PNG
      </p>
      <Modal
        show={showCropModal}
        onHide={() => setShowCropModal(false)}
        size="lg"
        centered
      >
        <ModalHeader
          title="Banner Image"
          description="Zoom to select the desired area for the event banner"
        />
        <ImageCropper
          image={originalImg}
          aspectRatio={21 / 9}
          onCropDone={(imgCroppedArea) =>
            onCropDone(
              imgCroppedArea,
              (link) =>
                onUpdate((prev) => {
                  if (prev.mainImages.includes(link)) {
                    return prev;
                  }
                  return {
                    ...prev,
                    mainImages: [...prev.mainImages, link],
                  };
                }),
              () => setShowCropModal(false)
            )
          }
          onCropCancel={() => onCropCancel(() => setShowCropModal(false))}
        />
      </Modal>
    </SectionWrapper>
  );
}
