import { Link } from "react-router-dom";

import { FaChevronRight } from "react-icons/fa6";

import styles from "./Stat.module.scss";

export default function Stat({ title, value, link }) {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      <Link to={link}>
        <div className={styles.value}>
          <span>{value}</span>
          <FaChevronRight />
        </div>
      </Link>
    </div>
  );
}
