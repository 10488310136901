import ActionButtons from "../../UI/Buttons/ActionButtons";
import InputGroup from "../../UI/Input/InputGroup";
import GoogleMap from "./GoogleMap";

import styles from "./AddressInputForm.module.scss";

export default function AddressInputForm({
  address,
  addressSetters,
  error,
  onValidateAddress,
  onReset,
  onPinLocation,
}) {
  const {
    venue,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    country,
    geometry,
  } = address;

  const {
    setVenue,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setProvince,
    setPostalCode,
    setCountry,
  } = addressSetters;

  const addressActionButtons = [
    {
      text: "Validate Address",
      variant: "primary",
      onClick: () => onValidateAddress(true),
    },
    {
      text: "Reset",
      variant: "secondary",
      onClick: onReset,
    },
  ];

  return (
    <form className={styles["address-input-form"]}>
      {error && <p className="error">{error}</p>}
      <InputGroup
        type="text"
        id="add-event-venue"
        label="Venue"
        placeholder="Venue"
        required
        className={styles["text-input"]}
        value={venue}
        onChange={(e) => setVenue(e.target.value)}
      />
      <InputGroup
        type="text"
        id="add-event-address-line-1"
        label="Address Line 1"
        placeholder="Street Address"
        required
        className={styles["text-input"]}
        value={addressLine1}
        onChange={(e) => setAddressLine1(e.target.value)}
      />
      <InputGroup
        type="text"
        id="add-event-address-line-2"
        label="Address Line 2"
        placeholder="Apt/Suite/Bldg/Unit"
        className={styles["text-input"]}
        value={addressLine2}
        onChange={(e) => setAddressLine2(e.target.value)}
      />
      <div className={styles["input-row"]}>
        <InputGroup
          type="text"
          id="add-event-city"
          label="City"
          placeholder="City"
          required
          className={styles["text-input"]}
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
        <InputGroup
          type="text"
          id="add-event-postal-code"
          label="Zip/Postal Code"
          placeholder="Zip/Postal Code"
          required
          className={styles["text-input"]}
          value={postalCode}
          onChange={(e) => setPostalCode(e.target.value)}
        />
      </div>
      <div className={styles["input-row"]}>
        <InputGroup
          type="text"
          id="add-event-country"
          label="Country"
          placeholder="Country"
          required
          className={styles["text-input"]}
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        />
        <InputGroup
          type="text"
          id="add-event-province"
          label="State/Province"
          placeholder="State/Province"
          required
          className={styles["text-input"]}
          value={province}
          onChange={(e) => setProvince(e.target.value)}
        />
      </div>
      {geometry && (
        <div className={styles["map-wrapper"]}>
          <GoogleMap latLng={geometry} onPinLocation={onPinLocation} />
          <p>
            Adjust the marker on the map if it doesn't precisely match your
            location. This helps improve address data quality.
          </p>
        </div>
      )}
      <ActionButtons
        buttons={addressActionButtons}
        className={styles["action-buttons-flex-start"]}
      />
    </form>
  );
}
