import ActionButtons from "../../UI/Buttons/ActionButtons";
import HorizontalRule from "../../UI/HorizontalRule";

import styles from "./AddressConfirmation.module.scss";
import GoogleMap from "./GoogleMap";

export default function AddressConfirmation({
  address,
  onReset,
  onDiscard,
  onSave,
}) {
  const {
    venue,
    geometry,
    addressLine1,
    addressLine2,
    city,
    province,
    postalCode,
    country,
  } = address;

  const addressActionButtons = [
    {
      text: "Address Validated",
      variant: "inactive",
      disabled: true,
    },
    {
      text: "Reset",
      variant: "secondary",
      onClick: onReset,
    },
  ];

  const formActionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: onDiscard,
    },
    {
      text: "Save & Continue",
      variant: "primary",
      onClick: onSave,
    },
  ];

  return (
    <>
      <div className={styles.address}>
        <h2 className={styles.title}>Validated Address</h2>
        <p>
          {venue && <span>{venue}, </span>}
          {addressLine1 && <span>{addressLine1}, </span>}
          {addressLine2 && <span>{addressLine2}, </span>}
        </p>
        <p>
          {city && <span>{city}, </span>}
          {province && <span>{province}, </span>}
          {country}
        </p>
        <p>{postalCode}</p>
      </div>
      {geometry && <GoogleMap latLng={geometry} isValidatedAddress={true} />}
      {/* <ActionButtons
        buttons={addressActionButtons}
        className={styles["action-buttons-flex-start"]}
      /> */}
      <HorizontalRule />
      <ActionButtons
        buttons={formActionButtons}
        className={styles["action-buttons-flex-end"]}
      />
    </>
  );
}
