import { Outlet } from "react-router-dom";

import styles from "./EventsLayout.module.scss";
import EventNav from "../NavBar/EventNav/EventNav";

export default function EventsLayout() {
  return (
    <div className={styles["events-layout"]}>
      <aside className={styles["sub-nav"]}>
        <EventNav />
      </aside>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
}
