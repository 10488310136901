import React, { useState } from "react";
import styles from "./SocialInputGroup.module.scss";
import Button from "../../../UI/Buttons/Button";
import { RiAddLargeFill } from "react-icons/ri";
import SocialIcon from "./SocialIcon";

function SocialInputGroup({ platform, onAddAccount }) {
  const [id, setId] = useState("");

  const addAccountHandler = (accountId) => {
    onAddAccount(accountId);
  };

  let platformText;
  switch (platform) {
    case "instagram":
      platformText = "Instagram";
      break;
    case "facebook":
      platformText = "Facebook";
      break;
    case "youtube":
      platformText = "YouTube";
      break;
    case "x":
      platformText = "X";
      break;
    case "tiktok":
      platformText = "TikTok";
      break;
    default:
      break;
  }

  return (
    <div className={styles.container}>
      <div className={styles["input-group"]}>
        <div className={styles.label}>
          <SocialIcon className={styles["icon"]} platform={platform} />
          {platformText}
        </div>
        <label htmlFor={`${platform}-id`} style={{ display: "none" }}>
          {platformText}
        </label>
        <input
          id={`${platform}-id`}
          type="text"
          value={id}
          onChange={(e) => setId(e.target.value)}
          placeholder={`${platformText} ID`}
          className={styles.input}
        />
      </div>
      <Button
        variant="secondary"
        className={styles["add-btn"]}
        onClick={() => addAccountHandler(id)}
      >
        <RiAddLargeFill />
      </Button>
    </div>
  );
}

export default SocialInputGroup;
