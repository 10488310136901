import axios from "axios";

export const addressValidation = async (addressObj) => {
  try {
    const res = await axios.post(
      `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_MAPS_APIKEY}
          
          `,
      addressObj,
      { "Content-Type": "application/json" }
    );

    console.log(res.data);

    const validationResult = res.data.result;

    if (validationResult.address.missingComponentTypes) {
      return {
        error: "Invalid Address",
      };
    }

    if (!validationResult.verdict.hasOwnProperty("addressComplete")) {
      return {
        error: "Address Not Completed",
      };
    }

    // const missingComponents =
    //   validationResult.address.missingComponentTypes.map((component) => {
    //     switch (component) {
    //       case "street_number":
    //         return "Street Number";
    //       case "route":
    //         return "Street Name";
    //       case "locality":
    //         return "City";
    //       case "administrative_area_level_1":
    //         return "State/Province";
    //       case "postal_code":
    //         return "Postal Code";
    //       case "country":
    //         return "Country";
    //       default:
    //         return null;
    //     }
    //   });

    // return {
    //   error: `Invalid Address: Missing ${missingComponents.join(", ")}`,
    // };
    return validationResult;
  } catch (error) {
    console.log(error);
    console.log(error.response.data.error.message);
    return { error: error.response.data.error.message };
  }
};
