import RadioButtons from "../../UI/Buttons/RadioButtons";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./EventFormat.module.scss";

const typeOptions = [
  { text: "In-Person", value: "in-person" },
  { text: "Stream", value: "stream" },
  { text: "Hybrid", value: "hybrid" },
];

export default function EventType({ format, onUpdate }) {
  return (
    <SectionWrapper
      title="Event Format"
      description="Help people to discover your event and let patrons know where to show up"
    >
      <RadioButtons
        buttons={typeOptions}
        currentState={format}
        onChangeValue={(value) =>
          onUpdate((prev) => ({ ...prev, format: value }))
        }
        className={styles["button-group"]}
      />

      <p>
        Live stream events have unique pages where you can add links to
        livestreams and more
      </p>
    </SectionWrapper>
  );
}
