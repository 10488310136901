import Stat from "./Stat";
import StatCard from "./StatCard";

import styles from "./ProfileStats.module.scss";

function ProfileStats() {
  const stats = [
    { title: "Events Created", value: "0", link: "#" },
    { title: "Total Ticket Buyers", value: "0 people", link: "#" },
    { title: "Stream Views", value: "0", link: "#" },
    { title: "In-Person", value: "0 people", link: "#" },
    { title: "Event Sharing", value: "0", link: "#" },
    { title: "Event Shared the most", value: "No event", link: "#" },
  ];

  return (
    <section className={styles["profile-stats"]}>
      <h2 className={styles.title}>Profile Stats</h2>
      <p className={styles.description}>Here is an average total to Nov 21</p>
      <div className={styles["stats-grids"]}>
        <StatCard>
          <Stat
            title={stats[0].title}
            value={stats[0].value}
            link={stats[0].link}
          />
        </StatCard>
        <StatCard>
          <Stat
            title={stats[1].title}
            value={stats[1].value}
            link={stats[1].link}
          />
        </StatCard>
        <StatCard>
          <Stat
            title={stats[2].title}
            value={stats[2].value}
            link={stats[2].link}
          />
        </StatCard>
        <StatCard>
          <Stat
            title={stats[3].title}
            value={stats[3].value}
            link={stats[3].link}
          />
        </StatCard>
        <StatCard>
          <Stat
            title={stats[4].title}
            value={stats[4].value}
            link={stats[4].link}
          />
          <Stat
            title={stats[5].title}
            value={stats[5].value}
            link={stats[5].link}
          />
        </StatCard>
        <StatCard>
          <div className={styles.graph}>
            <h3 className={styles["graph-title"]}>Sale Graph</h3>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/56149e94a2dfab3cffe4078ca9872638fab4d1c59c4c5c4c088f6a5dcbb6de90?placeholderIfAbsent=true&apiKey=d92a299f0cfc4f17bca506c7d54a7408"
              alt="Sales graph"
              className={styles["graph-img"]}
            />
            <div className={styles["x-axis"]}>
              <span>Jan</span>
              <span>Feb</span>
              <span>March</span>
              <span>April</span>
              <span>May</span>
            </div>
          </div>
        </StatCard>
      </div>
    </section>
  );
}

export default ProfileStats;
