import { useState } from "react";

import { FaLink } from "react-icons/fa6";
// import useInput from "../../../../hooks/useInput";
// import validateVideoLink from "../../../../utils/validation/video-link-validation";

import styles from "./DescriptionVideoInput.module.scss";

export default function DescriptionVideoInput({
  // isLinked,
  // videoList,
  // linkedVideo,
  onAddLink,
  error,
  // onRemoveLink,
}) {
  // const {
  //   value: link,
  //   isValid: linkIsValid,
  //   hasError: linkHasError,
  //   error: linkError,
  //   valueChangeHandler: linkChangeHandler,
  //   blurHandler: linkBlurHandler,
  // } = useInput(validateVideoLink, { videoList: videoList || [] });

  const [videoUrl, setVideoUrl] = useState("");

  return (
    <>
      <div className={styles["input-group"]}>
        {/* {isLinked && (
          <button className={styles["remove-btn"]} onClick={onRemoveLink}>
            <FaXmark />
          </button>
        )} */}
        <div className={styles.label}>Video Link</div>
        <input
          type="text"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          // onBlur={linkBlurHandler}
          placeholder="Enter video link and press the blue button"
          className={styles.input}
          // disabled={isLinked}
        />
        <button
          variant="secondary"
          className={`${styles["add-btn"]} ${
            // isLinked ? styles.linked :
            styles.active
          }`}
          onClick={() => onAddLink(videoUrl)}
          // disabled={!linkIsValid || isLinked}
          // disabled={!linkIsValid}
        >
          {/* {isLinked ? "Linked" : <FaLink />} */}
          <FaLink />
        </button>
      </div>
      {error && <p className="error">{error}</p>}
      {/* {linkHasError &&
        linkError.map((err) => (
          <p key={err} className="error">
            {err}
          </p>
        ))} */}
    </>
  );
}
