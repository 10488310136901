import { useState } from "react";
import ActionButtons from "../../UI/Buttons/ActionButtons";
import EventDateTime from "./EventDateTime";
import EventInfo from "./EventInfo";
import EventLanguage from "./EventLanguage";
import EventFormat from "./EventFormat";

import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { eventFormActions } from "../../../store/redux/event-form-slice";
import FormWrapper from "../../UI/Layout/FormWrapper";
import HorizontalRule from "../../UI/HorizontalRule";
import useScrollToTop from "../../../hooks/useScrollToTop";

export default function BasicInfoStep() {
  useScrollToTop();

  const {
    title: storedTitle,
    performance: storedPerformance,
    category: storedCategory,
    type: storedType,
    format: storedFormat,
    startTime: storedStartTime,
    endTime: storedEndTime,
    timeZone: storedTimeZone,
    language: storedLanguage,
  } = useSelector((state) => state.eventForm);

  const [basicInfo, setBasicInfo] = useState({
    title: storedTitle || "",
    performance: storedPerformance || "",
    category: storedCategory || "",
    type: storedType || "",
    format: storedFormat || "in-person",
    startTime: storedStartTime || "",
    endTime: storedEndTime || "",
    timeZone: storedTimeZone || "",
    language: storedLanguage || [],
  });

  const dispatch = useDispatch();

  const saveHandler = () => {
    dispatch(eventFormActions.submitBasicInfo(basicInfo));
    dispatch(eventFormActions.nextStep());
  };

  const discardHandler = () => {
    dispatch(eventFormActions.discardBasicInfo());
    setBasicInfo({
      title: "",
      performance: "",
      category: "",
      type: "",
      format: "in-person",
      startTime: new Date().toISOString(),
      endTime: new Date().toISOString(),
      timeZone: "",
      language: [],
    });
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
    },
    {
      text: "Save & Continue",
      variant: "primary",
      onClick: saveHandler,
    },
  ];

  return (
    <FormWrapper formTitle="Basic Event Information">
      <EventInfo {...basicInfo} onUpdate={setBasicInfo} />
      <HorizontalRule />
      <EventFormat {...basicInfo} onUpdate={setBasicInfo} />
      <HorizontalRule />
      <EventDateTime {...basicInfo} onUpdate={setBasicInfo} />
      <HorizontalRule />
      <EventLanguage {...basicInfo} onUpdate={setBasicInfo} />
      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons-flex-end"]}
      />
    </FormWrapper>
  );
}
