import { useSelector } from "react-redux";
import BasicInfoStep from "../../components/Events/BasicInfo";
import MainPageStep from "../../components/Events/MainPage";
import PageWrapper from "../../components/UI/Layout/PageWrapper";
import LocationStep from "../../components/Events/Location";
import TicketingStep from "../../components/Events/Ticketing";

export default function AddEventPage() {
  const { currStep } = useSelector((state) => state.eventForm);

  return (
    <PageWrapper>
      {currStep === 1 && <BasicInfoStep />}
      {currStep === 2 && <MainPageStep />}
      {currStep === 3 && <LocationStep />}
      {currStep === 4 && <TicketingStep />}
    </PageWrapper>
  );
}
