import { FaCheck } from "react-icons/fa6";
import styles from "./Checkbox.module.scss";

export default function Checkbox({ id, value, checked, onCheck, text }) {
  return (
    <label htmlFor={id} className={styles.option}>
      <input
        type="checkbox"
        id={id}
        value={value}
        className={styles["input-checkbox"]}
        checked={checked}
        onChange={onCheck}
      />
      <div
        className={`${styles["custom-checkbox"]} ${
          checked ? styles["checked"] : ""
        }`}
      >
        {checked && <FaCheck />}
      </div>
      <div className={styles["option-label"]}>{text}</div>
    </label>
  );
}
