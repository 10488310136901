import { useCallback, useState } from "react";

import DescriptionVideoInput from "./DescriptionVideoInput";
import ImageThumbnail from "../../../UI/Image/ImageThumbnail";
import LinkedVideoThumbnail from "./LinkedVideoThumbnail";
import DragAndSort from "../../../UI/DragAndSort";
import {
  extractYoutubeVideoId,
  fetchYouTubeDetails,
} from "../../../../utils/fetch-video-data";

import styles from "./DescriptionMedia.module.scss";
import { MdAddBox } from "react-icons/md";

export default function DescriptionMedia({
  videos,
  images,
  onUpdate,
  onRemove,
}) {
  const [showVideoInput, setShowVideoInput] = useState(false);
  const [videoError, setVideoError] = useState(null);

  const addVideoHandler = async (url) => {
    const videoId = extractYoutubeVideoId(url);
    if (!videoId) {
      setVideoError("Invalid Youtube video link");
      return;
    }

    if (videos.some((video) => video.id === videoId)) {
      setVideoError("Video already embedded");
      return;
    }

    const videoDetails = await fetchYouTubeDetails(videoId);
    if (!videoDetails) {
      setVideoError("Cannot find video");
      return;
    }

    onUpdate("videos", [
      ...videos,
      {
        id: videoId,
        title: videoDetails.title,
        thumbnail: videoDetails.thumbnail,
      },
    ]);
    setShowVideoInput(false);
    setVideoError(null);
  };

  const fileChangeHandler = useCallback(
    (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        onUpdate("images", [...images, e.target.result]);
      };
      reader.readAsDataURL(file);
    },
    [images, onUpdate]
  );

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["video-section"]}>
        {showVideoInput && (
          <DescriptionVideoInput
            // isLinked={false}
            videoList={videos}
            error={videoError}
            onAddLink={addVideoHandler}
          />
        )}
        {videos && videos.length > 0 && (
          <div className={styles["video-list"]}>
            <DragAndSort
              items={videos}
              onUpdate={onUpdate.bind(null, "videos")}
            >
              {videos.map((video) => (
                <LinkedVideoThumbnail
                  key={video.id}
                  {...video}
                  onRemove={onRemove.bind(null, "videos", video.id)}
                />
              ))}
            </DragAndSort>
          </div>
        )}
      </div>

      <div className={styles["image-section"]}>
        {images.length > 0 && (
          <div className={styles["image-list"]}>
            {images.map((image, index) => (
              <ImageThumbnail
                key={index}
                image={image}
                onRemove={onRemove.bind(null, "images", image)}
              />
            ))}
          </div>
        )}
      </div>

      <div className={styles["media-actions"]}>
        <button
          className={styles["add-media-button"]}
          onClick={() => document.getElementById("description-images").click()}
        >
          <span>Add Image</span>
          <MdAddBox className={styles["add-icon"]} />
        </button>
        <input
          id="description-images"
          type="file"
          accept="image/*"
          multiple
          onClick={(e) => e.stopPropagation()}
          onChange={fileChangeHandler}
          style={{ display: "none" }}
        />
        <button
          className={styles["add-media-button"]}
          onClick={() => setShowVideoInput(true)}
        >
          <span>Add Video</span>
          <MdAddBox className={styles["add-icon"]} />
        </button>
      </div>
    </div>
  );
}
