import { Link } from "react-router-dom";

import styles from "./LinkedVideoThumbnail.module.scss";
import { FaXmark } from "react-icons/fa6";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export default function LinkedVideoThumbnail({
  thumbnail,
  title,
  id,
  onRemove,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const removeHandler = (e) => {
    e.stopPropagation();
    onRemove();
  };

  const dndStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className={styles.wrapper}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={dndStyle}
    >
      <button
        className={styles["remove-btn"]}
        onPointerDown={(e) => {
          e.stopPropagation(); // Prevent drag initiation
        }}
        onClick={removeHandler}
      >
        <FaXmark />
      </button>
      {/* <Link
        to={`https://www.youtube.com/watch?v=${id}`}
        target="_blank"
        className={styles.content}
      > */}
      <div className={styles.content}>
        <img src={thumbnail} alt={title} className={styles.thumbnail} />
        <p className={styles.title}>{title}</p>
      </div>
      {/* </Link> */}
    </div>
  );
}
