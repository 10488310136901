import styles from "./Button.module.scss";

function Button({
  type = "button",
  children,
  variant = "primary",
  disabled,
  onClick,
  className,
}) {
  return (
    <button
      className={`${styles.button} ${styles[variant]} ${className}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
}

export default Button;
