export function timeFormatter(time) {
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
  };

  const timeObj = new Date(time);

  return timeObj.toLocaleString("en-CA", options);
}
