import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { useEffect, useRef } from "react";

import styles from "./GoogleMap.module.scss";

export default function GoogleMap({
  latLng,
  isValidatedAddress,
  onPinLocation,
}) {
  const mapRef = useRef(null);
  const mapLib = useMapsLibrary("maps");
  const markerLib = useMapsLibrary("marker");
  const geocodeLib = useMapsLibrary("geocoding");

  useEffect(() => {
    if (latLng && mapLib && markerLib && geocodeLib) {
      // Initialize map
      const map = new mapLib.Map(mapRef.current, {
        center: latLng,
        zoom: 18,
        mapId: "add-event-map",
      });

      const glyphImg = document.createElement("img");

      glyphImg.src = "/icon-bright.svg";

      const glyphSvgPinElement = new markerLib.PinElement({
        glyph: glyphImg,
        glyphColor: "#ffffff",
        background: "#2a2c3a",
        borderColor: "#ffffff",
        scale: 2,
      });

      // Place a draggable marker on the map
      const marker = new markerLib.AdvancedMarkerElement({
        position: latLng,
        map: map,
        gmpDraggable: isValidatedAddress ? false : true,
        content: glyphSvgPinElement.element,
      });

      // Add a geocoder to transform lat/lng to address
      const geocoder = new geocodeLib.Geocoder();

      marker.addListener("dragend", (e) => {
        const newLatLng = {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        };

        geocoder.geocode({ location: newLatLng }, (results, status) => {
          if (status === "OK") {
            console.log(results);
            onPinLocation({
              addressComponents: results[0].address_components,
              formattedAddress: results[0].formatted_address,
              geometry: newLatLng,
            });
          }
        });
      });
    }
  }, [
    latLng,
    mapLib,
    markerLib,
    geocodeLib,
    isValidatedAddress,
    onPinLocation,
  ]);

  return <div ref={mapRef} className={styles.map} />;
}
