import { useEffect, useState } from "react";
import SectionWrapper from "../../UI/Layout/SectionWrapper";

import styles from "./EventDateTime.module.scss";
import DatePicker from "../../UI/DatePicker";
import { FloatingLabel, Form } from "react-bootstrap";
import { MdAdd } from "react-icons/md";

const timeZoneOptions = [
  { text: "Select time zone", value: "" },
  { text: "UTC-12:00 - Baker Island", value: "UTC-12:00" },
  { text: "UTC-11:00 - Pago Pago", value: "UTC-11:00" },
  { text: "UTC-10:00 - Hawaii, Honolulu (No DST)", value: "UTC-10:00" },
  {
    text: "UTC-09:00 - Alaska, Anchorage (UTC-08:00 during DST)",
    value: "UTC-09:00",
  },
  {
    text: "UTC-08:00 - Los Angeles, Vancouver (UTC-07:00 during DST)",
    value: "UTC-08:00",
  },
  {
    text: "UTC-07:00 - Denver, Phoenix (Phoenix - No DST, Denver UTC-06:00 during DST)",
    value: "UTC-07:00",
  },
  {
    text: "UTC-06:00 - Chicago, Mexico City (Chicago UTC-05:00 during DST)",
    value: "UTC-06:00",
  },
  {
    text: "UTC-05:00 - New York, Toronto (UTC-04:00 during DST)",
    value: "UTC-05:00",
  },
  {
    text: "UTC-04:00 - Santiago, Caracas (Caracas - No DST, Santiago UTC-03:00 during DST)",
    value: "UTC-04:00",
  },
  {
    text: "UTC-03:00 - Buenos Aires, São Paulo (São Paulo UTC-02:00 during DST)",
    value: "UTC-03:00",
  },
  {
    text: "UTC-02:00 - South Georgia, Mid-Atlantic (No DST)",
    value: "UTC-02:00",
  },
  {
    text: "UTC-01:00 - Azores (UTC+00:00 during DST), Cape Verde (No DST)",
    value: "UTC-01:00",
  },
  {
    text: "UTC+00:00 - London, Dublin (UTC+01:00 during DST)",
    value: "UTC+00:00",
  },
  {
    text: "UTC+01:00 - Berlin, Paris (UTC+02:00 during DST)",
    value: "UTC+01:00",
  },
  { text: "UTC+02:00 - Cairo, Johannesburg (No DST)", value: "UTC+02:00" },
  {
    text: "UTC+03:00 - Moscow, Riyadh (Moscow - No DST, Riyadh - No DST)",
    value: "UTC+03:00",
  },
  {
    text: "UTC+04:00 - Dubai, Baku (Baku UTC+05:00 during DST)",
    value: "UTC+04:00",
  },
  { text: "UTC+05:00 - Karachi, Tashkent (No DST)", value: "UTC+05:00" },
  { text: "UTC+06:00 - Dhaka, Almaty (No DST)", value: "UTC+06:00" },
  { text: "UTC+07:00 - Bangkok, Jakarta (No DST)", value: "UTC+07:00" },
  { text: "UTC+08:00 - Beijing, Singapore (No DST)", value: "UTC+08:00" },
  { text: "UTC+09:00 - Tokyo, Seoul (No DST)", value: "UTC+09:00" },
  {
    text: "UTC+10:00 - Sydney, Guam (Sydney UTC+11:00 during DST)",
    value: "UTC+10:00",
  },
  { text: "UTC+11:00 - Noumea, Solomon Islands (No DST)", value: "UTC+11:00" },
  {
    text: "UTC+12:00 - Auckland, Fiji (UTC+13:00 during DST)",
    value: "UTC+12:00",
  },
];

export default function EventDateTime({
  startTime: storedStartTime,
  endTime: storedEndTime,
  timeZone: storedTimeZone,
  onUpdate,
}) {
  const [startTime, setStartTime] = useState(new Date(storedStartTime));
  const [endTime, setEndTime] = useState(new Date(storedEndTime));

  useEffect(() => {
    const updatedEndTime = new Date(endTime);
    updatedEndTime.setFullYear(startTime.getFullYear());
    updatedEndTime.setMonth(startTime.getMonth());
    updatedEndTime.setDate(startTime.getDate());
    setEndTime(updatedEndTime);
    onUpdate((prev) => ({
      ...prev,
      endTime: updatedEndTime.toISOString(),
    }));
  }, [startTime, onUpdate]);

  useEffect(() => {
    setStartTime(new Date(storedStartTime));
    setEndTime(new Date(storedEndTime));
  }, [storedStartTime, storedEndTime]);

  const changeTimeHandler = (timeType, time) => {
    timeType === "startTime" ? setStartTime(time) : setEndTime(time);
    onUpdate((prev) => ({
      ...prev,
      [timeType]: time.toISOString(),
    }));
  };

  return (
    <SectionWrapper
      title="Date and time"
      description="Stream available 15 minutes before the event"
    >
      <div className={styles["datetime-inputs"]}>
        <div className={styles["picker-group"]}>
          <p>
            Date <span className={styles.required}>*</span>
          </p>
          <DatePicker
            date={startTime}
            onChangeDate={(time) => changeTimeHandler("startTime", time)}
          />
        </div>
        <div className={styles["time-inputs"]}>
          <div className={styles["picker-group"]}>
            <p>
              Start Time <span className={styles.required}>*</span>
            </p>
            <DatePicker
              date={startTime}
              onChangeDate={(time) => changeTimeHandler("startTime", time)}
              isTimeOnly={true}
              options={{
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                dateFormat: "h:mm aa",
              }}
            />
          </div>
          <div className={styles["picker-group"]}>
            <p>
              End Time <span className={styles.required}>*</span>
            </p>
            <DatePicker
              date={endTime}
              onChangeDate={(time) => changeTimeHandler("endTime", time)}
              isTimeOnly={true}
              options={{
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeIntervals: 15,
                timeCaption: "Time",
                dateFormat: "h:mm aa",
              }}
            />
          </div>
        </div>
      </div>
      <button type="button" className={styles["add-datetime-btn"]}>
        <MdAdd />
        Add extra date and time
      </button>

      <div className={styles["timezone-select"]}>
        <FloatingLabel controlId="floatingSelectGrid" label="Time Zone">
          <Form.Select
            value={storedTimeZone}
            onChange={(e) =>
              onUpdate((prev) => ({ ...prev, timeZone: e.target.value }))
            }
          >
            {timeZoneOptions.map((timeZone, index) => (
              <option key={index} value={timeZone.value}>
                {timeZone.text}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </div>
    </SectionWrapper>
  );
}
