import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventFormActions } from "../../../store/redux/event-form-slice";

import MainImages from "./MainImages";
import HorizontalRule from "../../UI/HorizontalRule";
import FeatureImage from "./FeatureImage";
import SocialMedia from "./SocialMedia/SocialMedia";
import Description from "./Description/Description";
import ActionButtons from "../../UI/Buttons/ActionButtons";
import FormWrapper from "../../UI/Layout/FormWrapper";

import styles from "./index.module.scss";
import useScrollToTop from "../../../hooks/useScrollToTop";

export default function MainPageStep() {
  useScrollToTop();

  const {
    mainImages: storedMainImages,
    featureImage: storedFeatureImage,
    description: storedDescription,
    socialMediaAccounts: storedSocialMediaAccounts,
  } = useSelector((state) => state.eventForm);

  const [mainPageInfo, setMainPageInfo] = useState({
    mainImages: storedMainImages || [],
    featureImage: storedFeatureImage || [],
    description: storedDescription || {
      title: "",
      content: "",
      videos: [],
      images: [],
    },
    socialMediaAccounts: storedSocialMediaAccounts || [],
  });

  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(eventFormActions.submitMainPage(mainPageInfo));
    dispatch(eventFormActions.nextStep());
  };

  const discardHandler = () => {
    dispatch(eventFormActions.discardMainPage());
    setMainPageInfo({
      mainImages: [],
      featureImage: [],
      description: {
        title: "",
        content: "",
        videos: [],
        images: [],
      },
      socialMediaAccounts: [],
    });
  };

  const actionButtons = [
    {
      text: "Discard",
      variant: "secondary",
      onClick: discardHandler,
    },
    {
      text: "Save & Continue",
      variant: "primary",
      onClick: submitHandler,
    },
  ];

  return (
    <FormWrapper formTitle="Main Event Page Set Up">
      <MainImages {...mainPageInfo} onUpdate={setMainPageInfo} />
      <HorizontalRule />
      <FeatureImage {...mainPageInfo} onUpdate={setMainPageInfo} />
      <HorizontalRule />
      <Description {...mainPageInfo} onUpdate={setMainPageInfo} />
      <HorizontalRule />
      <SocialMedia {...mainPageInfo} onUpdate={setMainPageInfo} />

      <ActionButtons
        buttons={actionButtons}
        className={styles["action-buttons-flex-end"]}
      />
    </FormWrapper>
  );
}
