import { useRef } from "react";
import SunEditor from "suneditor-react";

import DescriptionMedia from "./DescriptionMedia";
import EditableContent from "../../../UI/EditableContent";
import SectionWrapper from "../../../UI/Layout/SectionWrapper";

import styles from "./Description.module.scss";

const buttonList = [
  ["undo", "redo", "removeFormat"],
  [
    "bold",
    "underline",
    "italic",
    "strike",
    "align",
    "fontSize",
    "fontColor",
    "hiliteColor",
  ],
  ["horizontalRule", "blockquote"],
  ["table"],
];

export default function Description({ description, onUpdate }) {
  const editorRef = useRef(null);
  const getSunEditorInstance = (sunEditor) => {
    editorRef.current = sunEditor;
  };

  const updateHandler = (key, value) => {
    onUpdate((prev) => ({
      ...prev,
      description: { ...prev.description, [key]: value },
    }));
  };

  const removeHandler = (mediaType, id) => {
    const updatedDescription = { ...description };

    switch (mediaType) {
      case "images":
        updatedDescription.images = description.images.filter(
          (imageUrl) => imageUrl !== id
        );
        break;
      case "videos":
        updatedDescription.videos = description.videos.filter(
          (video) => video.id !== id
        );
        break;
      default:
        return;
    }

    onUpdate((prev) => ({ ...prev, description: updatedDescription }));
  };

  return (
    <SectionWrapper
      title="Description"
      description="Add more details to youe event like sponsors, important dates or featured guests"
    >
      <EditableContent
        inputId="event-description"
        inputLabel="Title"
        title={description.title}
        onChangeTitle={updateHandler.bind(null, "title")}
        body={null}
      />
      <SunEditor
        id="event-description-editor"
        onChange={updateHandler.bind(null, "content")}
        height="350px"
        getSunEditorInstance={getSunEditorInstance}
        defaultValue={description.content}
        setOptions={{
          height: 200,
          buttonList: buttonList,
        }}
      />

      <p className={styles["char-count"]}>
        {1500 - editorRef.current?.getText().length || 1500} characters
        remaining.
      </p>

      <DescriptionMedia
        videos={description.videos}
        images={description.images}
        onUpdate={updateHandler}
        onRemove={removeHandler}
      />
    </SectionWrapper>
  );
}
