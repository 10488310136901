import styles from "./SectionWrapper.module.scss";

export default function SectionWrapper({
  children,
  className,
  title,
  titleOptions,
  description,
}) {
  return (
    <section className={styles["section-wrapper"]}>
      <div className={styles["heading"]}>
        <div className={styles.title}>
          <h2
            className={
              titleOptions?.className
                ? titleOptions.className
                : styles["title-text"]
            }
          >
            {title}
          </h2>
          {titleOptions?.tooltipIcon && titleOptions.tooltipIcon}
        </div>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={className}>{children}</div>
    </section>
  );
}
