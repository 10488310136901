import { DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";

export default function DragAndSort({ items, onUpdate, children }) {
  const dragHandler = (event) => {
    const { active, over } = event;

    console.log(active, over);

    if (over && over.id !== active.id) {
      console.log("called!");
      const oldIndex = items.findIndex((video) => video.id === active.id);
      const newIndex = items.findIndex((video) => video.id === over.id);
      onUpdate(arrayMove(items, oldIndex, newIndex));
    }
  };

  return (
    <DndContext onDragEnd={dragHandler}>
      <SortableContext items={items}>{children}</SortableContext>
    </DndContext>
  );
}
