import { forwardRef } from "react";
import styles from "./InputGroup.module.scss";

const InputGroup = forwardRef(
  (
    {
      id,
      value,
      type,
      label,
      placeholder,
      className,
      onChange,
      required,
      disabled,
    },
    ref
  ) => {
    return (
      <div className={styles["input-group"]}>
        <label htmlFor={id}>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
        <input
          type={type}
          id={id}
          value={value}
          placeholder={placeholder}
          className={className}
          required={required}
          onChange={onChange}
          disabled={disabled}
          ref={ref}
        />
      </div>
    );
  }
);

export default InputGroup;
