import styles from "./FormWrapper.module.scss";

export default function FormWrapper({ formTitle, description, children }) {
  return (
    <div className="form-wrapper">
      <div className={styles.heading}>
        <h1 className={styles.title}>{formTitle}</h1>
        {description && <p className={styles.description}>{description}</p>}
      </div>
      {children}
    </div>
  );
}
