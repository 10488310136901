// import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../store/context/auth-context";
import { Link } from "react-router-dom";

import NavItem from "./NavItem";

import styles from "./NavBar.module.scss";
import { FaCircleQuestion } from "react-icons/fa6";
import { IoMdLogOut } from "react-icons/io";
import {
  FaBook,
  FaBullhorn,
  FaClipboardList,
  FaHome,
  FaUserCircle,
} from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { NavDisputeIcon, NavInfluencersIcon, NavReportsIcon } from "../Icons";

const navItems = [
  { icon: <FaHome />, title: "Home", path: "" },
  { icon: <MdEvent />, title: "Events", path: "events/add-event" },
  { icon: <FaClipboardList />, title: "Orders", path: "orders" },
  { icon: <FaUserCircle />, title: "My Page", path: "my-page" },
  { icon: <FaBullhorn />, title: "Marketing", path: "marketing" },
  { icon: <FaBook />, title: "Learning", path: "learning" },
  { icon: <NavReportsIcon />, title: "Reports", path: "reports" },
  { icon: <NavDisputeIcon />, title: "Dispute", path: "dispute" },
  {
    icon: <NavInfluencersIcon />,
    title: "Manage Influencers",
    path: "manage-influencers",
  },
];

export default function NavBar({ onClose }) {
  // const [activeSection, setActiveSection] = useState(null);

  // const toggleSection = (section) => {
  //   setActiveSection(section === activeSection ? null : section);
  // };

  const authCtx = useContext(AuthContext);

  return (
    <nav className={styles.nav}>
      <div className={styles["links-group"]}>
        {navItems.map((item) => (
          <NavItem
            key={item.title}
            icon={item.icon}
            title={item.title}
            path={item.path}
            onClose={onClose}
          />
        ))}

        <div className={styles["other-links"]}>
          <Link to="/faq" onClick={onClose}>
            <FaCircleQuestion />
            <span>FAQ</span>
          </Link>
          {authCtx.user && (
            <Link to="/login" onClick={() => authCtx.logout()}>
              <IoMdLogOut />
              <span>Log Out</span>
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}
